<template>
  <div class="qa-card">
    <div class="qa-card__question-wrapper">
      <div class="qa-card__question-icon">Q</div>
      <div class="qa-card__question" v-html="question" />
    </div>
    <div class="qa-card__answer-wrapper">
      <div class="qa-card__answer-icon">A</div>
      <div class="qa-card__answer" v-html="answer" />
    </div>
  </div>
</template>
<script>
export default {
  name: 'qa-card',

  props: {
    question: String,
    answer: String,
  },
};
</script>
<style lang="scss" scoped>
.qa-card {
  background-color: $white;
  border-radius: 5px;
  box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.1);
  padding: 5px;
  box-sizing: border-box;
  margin: 40px;
}

.qa-card__question-wrapper,
.qa-card__answer-wrapper {
  display: flex;
  margin: 20px;
}

.qa-card__question-icon,
.qa-card__answer-icon {
  min-width: 40px;
  min-height: 40px;
  text-align: center;
  line-height: 40px;
  font-weight: bold;
  background-color: $blue;
  color: $white;
  border-radius: 5px;
}

.qa-card__question {
  font-weight: bold;
}

.qa-card__question,
.qa-card__answer {
  font-weight: 19px;
  display: flex;
  align-items: center;
  flex-grow: 1;
  margin: 0 8px 0 20px;
}
</style>
