<template>
  <div id="app">
    <div class="app__header">FAQs</div>
    <router-view />
  </div>
</template>
<script>
export default {
  name: 'app',
};
</script>

<style lang="scss">
body {
  top: 0;
  left: 0;
  margin: 0;
  padding: 0;
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $black;
  background-color: #f0f2f5;
}
</style>
<style lang="scss" scoped>
#app {
  @include greater-than(1300px) {
    width: 1300px;
    margin: 100px auto 0;

    min-height: 500px;
  }
  min-height: 100vh;
  background: $white;
  padding: 5px 0;

  font-family: 'Poppins', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  letter-spacing: 0.35px;
  font-size: 15px;
  color: $black;
}

.app__header {
  color: $blue;
  font-weight: bold;
  font-size: 20px;
  margin: 20px;
}
</style>
