import Vue from 'vue';
import VueRouter from 'vue-router';

import FAQ from '../views/FAQ.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/:lang?',
    name: 'home',
    props: true,
    component: FAQ,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
