<template>
  <div class="tabs">
    <div ref="tabHeaderIntersection" class="tabs__header-intersection" />
    <div class="tabs__header" :class="{ 'tabs__header--sticky': headerIntersected }" ref="tabHeader">
      <simplebar ref="headerScrollbar">
        <div class="tabs__header-wrapper">
          <div
            v-for="(tab, i) in data"
            :key="i"
            class="tabs__tab-header"
            :class="{ 'tabs__tab-header--active': currentIndex === i }"
            v-text="tab.label"
            @click="changeActiveTab(i, $event)"
          />
        </div>
      </simplebar>
    </div>
    <slot name="content" :data="data[currentIndex].data"></slot>
  </div>
</template>
<script>
import { styler, tween } from 'popmotion';
import Simplebar from 'simplebar-vue';

import 'simplebar/dist/simplebar.min.css';

export default {
  name: 'tabs',

  components: {
    Simplebar,
  },

  props: {
    data: {
      type: Array,
    },
  },

  data() {
    return {
      headerWidth: 0,
      headerLeft: 0,
      headerContentWidth: 0,
      currentIndex: 0,
      headerIntersected: false,
    };
  },

  mounted() {
    const headerClientRect = this.$refs.tabHeader.getClientRects()[0];
    this.headerLeft = headerClientRect.left;
    this.headerWidth = headerClientRect.width;

    const observer = new IntersectionObserver(
      entries => {
        if (entries[0].intersectionRatio === 0) this.headerIntersected = true;
        else if (entries[0].intersectionRatio === 1) this.headerIntersected = false;
      },
      { threshold: [0, 1] }
    );

    observer.observe(this.$refs.tabHeaderIntersection);
  },

  methods: {
    changeActiveTab(index, e) {
      if (this.currentIndex === index) return;
      this.currentIndex = index;

      // Add two times 20px for css padding
      const maxScrollWidth = this.$refs.headerScrollbar.scrollElement.scrollWidth - this.headerWidth + 40;

      const headerStyler = styler(this.$refs.headerScrollbar.scrollElement);
      const elementClientRect = e.target.getClientRects()[0];
      // Subtract header left to get the relative position of the element
      const elementCenter = elementClientRect.x + elementClientRect.width / 2 - this.headerLeft;

      const currentScrollPosition = headerStyler.get('scrollLeft');
      const targetScrollPosition = currentScrollPosition + elementCenter - this.headerWidth / 2;

      tween({
        from: currentScrollPosition,
        to: Math.min(Math.max(targetScrollPosition, 0), maxScrollWidth),
        duration: 300,
      }).start(v => headerStyler.set('scrollLeft', v));
    },
  },
};
</script>
<style lang="scss">
.simplebar-track {
  @include smaller-than($screen-tablet) {
    display: none !important;
  }
}

.simplebar-scrollbar {
  height: 2px !important;
}

.simplebar-content-wrapper {
  background: linear-gradient(90deg, white 33%, rgba(0, 0, 0, 0)),
    linear-gradient(90deg, rgba(0, 0, 0, 0), white 66%) 100% 0,
    linear-gradient(270deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.15) 100%),
    linear-gradient(90deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.15) 100%) 100% 0;
  background-color: white;
  background-repeat: no-repeat;
  background-attachment: local, local, scroll, scroll;
  background-size: 25px 100%, 25px 100%, 10px 100%, 10px 100%;
}
</style>
<style lang="scss" scoped>
.tabs__header-intersection {
  height: 1px;
}

.tabs__header {
  position: sticky;
  top: 0;
  background-color: $white;
  transition: box-shadow $ease-in-out-cubic $animation-medium;
  box-shadow: inset 0 -1px 0 0 $light-gray;
}

.tabs__header--sticky {
  box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.1);
}

.tabs__header-wrapper {
  display: flex;
}

.tabs__tab-header {
  padding: 20px 25px;
  white-space: nowrap;
  color: $gray;
  transition: color $ease-in-out-cubic $animation-medium, box-shadow $ease-in-out-cubic $animation-medium;
  font-weight: bold;
  cursor: pointer;

  &:first-child {
    margin-left: 20px;
  }

  &:last-child {
    margin-right: 20px;
  }
}

.tabs__tab-header--active {
  color: $blue;
  box-shadow: inset 0 -2px 0 0 $blue;
}
</style>
