<template>
  <div class="home">
    <tabs :data="tabs">
      <template #content="{ data }">
        <qa-card v-for="(qa, i) in data" :key="i" :question="qa.question" :answer="qa.answer" />
      </template>
    </tabs>
  </div>
</template>

<script>
import axios from 'axios';

import QaCard from '../components/QaCard.vue';
import Tabs from '../components/Tabs.vue';

export default {
  name: 'home',

  props: {
    language: {
      type: String,
      default: 'EN',
    },
  },

  components: {
    Tabs,
    QaCard,
  },

  beforeRouteEnter(to, from, next) {
    const lang = to.params.lang || 'EN';

    axios.get(`v1/app/faqs/${lang}`).then(result => {
      const data = result.data.data;

      if (data) {
        next(vm => {
          vm.tabs = data.map(category => ({
            label: category.name,
            data: category.questions,
          }));
        });
      }
    });
  },

  beforeRouteUpdate(to, from, next) {
    const lang = to.params.lang || 'EN';

    axios.get(`v1/app/faqs/${lang}`).then(result => {
      const data = result.data.data;

      if (data) {
        this.tabs = data.map(category => ({
          label: category.name,
          data: category.questions,
        }));
        next();
      }
    });
  },

  data() {
    return {
      tabs: [{ label: 'Label', data: [] }],
    };
  },
};
</script>
